import '../styles/index.scss';


// ANIMATION

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        console.log(entry)
        if (entry.isIntersecting) {
            entry.target.classList.add('show');
        } else {
            entry.target.classList.remove('show');
        }
    });
});

const hiddenSection = document.querySelectorAll('.animate-hidden');
hiddenSection.forEach((el) => observer.observe(el));


// SMOOTH SCROLLING TO ANCHOR-TAGGED ELEMENTS ON THE PAGE
document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const offset = document.querySelector('.navbar').offsetHeight;
                const targetPosition = targetElement.offsetTop - offset;

                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });
});

// FORM POPUP

const form = document.getElementById("email-form");
const popup = document.getElementById("popup");
const overlay = document.createElement("div");

overlay.classList.add("popup-overlay");
form.addEventListener('submit', function (event) {
    event.preventDefault();
    const button = this.querySelector("button");
    button.setAttribute('disabled', true);
    button.setAttribute('opacity', 0.1);
    let formInput = document.getElementById('email').value;
    var data = this;
    var lang = 'en';
    if(window.location.pathname.indexOf("pl") > -1) {
      lang = 'pl';
    }
    fetch('../send.php?lang=' + lang, {
        method: 'POST',
        body: new FormData(data)
    }).then(res=>res.text())
        .then(function (data) {
            let response = JSON.parse(data);
            if(response.type === "success") {
                overlay.style.display = "block";
                popup.style.display = "flex";
                popup.style.alignItems = "center";
                popup.style.justifyContent = "center";
            } else {
                document.querySelector('.error-message').innerHTML = response.text
            }
            button.removeAttribute('disabled');
            button.removeAttribute('opacity');
    });
});

document.getElementById("form-section").appendChild(overlay);
overlay.addEventListener('click', function () {
    overlay.style.display = "none";
    popup.style.display = "none";
});

popup.addEventListener('click', function (event) {
    event.stopPropagation();
});

const closeButton = popup.querySelector("#close-popup");
closeButton.addEventListener("click", function () {
    overlay.style.display = "none";
    popup.style.display = "none";
});
document.addEventListener("DOMContentLoaded", function () {
    return {
        ajaxSubmit: ajaxSubmit,
    };
});


// VIDEO MOBILE

function playVideo() {
    document.querySelector('.video-mobile').play();
    document.removeEventListener('click', playVideo);
}

document.addEventListener('click', playVideo);

// SWITCH LANGUAGE

document.addEventListener("DOMContentLoaded", function() {
    const languageSwitch = document.getElementById('languageSwitch');

    languageSwitch.addEventListener('change', function() {
        window.location.href = languageSwitch.value;
    });
});

// CLOSE MOBILE MENU AFTER CLICKING THE LINK
document.addEventListener("DOMContentLoaded", function() {
    let links = document.querySelectorAll('.navbar-nav .nav-link');
    let offcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasNavbar'));

    links.forEach(link => {
        link.addEventListener('click', () => {
            offcanvas.hide();
        });
    });

    let languageSwitch = document.getElementById('languageSwitch');
    languageSwitch.addEventListener('change', () => {
    });
});